<template>
    <WitModal
        v-model="showModal"
        title="Add Step"
        variant="warning"
        @input="onChange"
        disableDBlock
        size="md"
        customClass="workflow-add-task-modal"
    >
        <div class="workflow-add-task-modal__type-container">
            <div
                v-for="(job, index) in jobTypes"
                :key="index"
                class="workflow-add-task-modal__type pointer-action"
                @click="setType(job.type)"
            >
                <div class="overflow-hidden workflow-add-task-modal__job-card">
                    <div class="workflow-add-task-modal__job-card__img">
                        <img :src="getImgUrl(job.image)" />
                    </div>
                    <div class="workflow-add-task-modal__job-card__text">
                        <div class="workflow-add-task-modal__job-card__name">
                            <h4 class="card-title">{{ job.name }}</h4>
                        </div>
                        <div class="workflow-add-task-modal__job-card__description card-text">
                            {{ job.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WitModal>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    data() {
        return {
            showModal: false,
            jobTypes: [
                {
                    name: 'Task',
                    type: 'Task',
                    image: 'workflow/step-types/task.png',
                    description: 'Run a single task: collect, process or make a report',
                },
                {
                    name: 'Group Task',
                    type: 'Map',
                    image: 'workflow/step-types/map.png',
                    description: 'Run multiple tasks for each date',
                },
            ],
        }
    },
    created() {
        this.showModal = this.value
    },
    methods: {
        close() {
            this.showModal = false
            this.onChange()
        },

        onChange() {
            this.$emit('input', this.showModal)
        },

        getImgUrl(pic) {
            return require('@/assets/' + pic)
        },

        setType(type) {
            this.$emit('addTask', type)
            this.close()
        },
    },
}
</script>

<style lang="scss">
.workflow-add-task-modal {
    .workflow-add-task-modal__type-container {
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;

        .workflow-add-task-modal__type {
            display: block;
            padding: 0;
            margin: 0 auto;

            .workflow-add-task-modal__job-card {
                margin: 1rem;
                padding: 0.5rem 1rem;
                background-color: transparent;
                max-width: 250px;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: space-evenly;
                border-radius: 8px;

                .workflow-add-task-modal__job-card__name {
                    text-align: center;

                    h4 {
                        margin-bottom: 10px;
                    }
                }

                .workflow-add-task-modal__job-card__img {
                    margin-bottom: 15px;

                    img {
                        max-width: 75px;
                        object-fit: contain;
                    }
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
}
</style>
